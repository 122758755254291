import React from 'react'

export default function Button({children}) {
  return (
    <>
    <button className='btnBlue'>{children}</button>
    </>
    
  )
}
